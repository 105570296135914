import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { PageProps, Link, graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  FaUserShield,
  FaAmazon,
  FaHdd,
  FaCommentDollar,
  FaGlobeAfrica,
} from "react-icons/fa";

const HomePage: React.FC<{
  price: string;
  serviceCharge: number;
  sym: string;
}> = ({ price, sym, serviceCharge }) => {
  const data = useStaticQuery(graphql`
    query Stq {
      site {
        siteMetadata {
          title
        }
      }

      placeholderImage: file(relativePath: { eq: "bg1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [gb, setGb] = useState("200");
  const [egress, setEgress] = useState("200");
  const usage = useMemo(() => {
    return (parseFloat(gb) - serviceCharge) * parseFloat(price);
  }, [gb]);
  const egressUsage = useMemo(() => {
    if (parseFloat(egress) < parseFloat(gb)) {
      return 0;
    }
    return (parseFloat(egress) - parseFloat(gb)) * parseFloat(price);
  }, [[gb, egress]]);

  return (
    <Layout>
      <SEO title="BigStorage | S3 Compatible, Affordable, fast, reliable cloud storage" />
      <div
        id="home"
        className="relative bg-white overflow-hidden bg-white rounded-lg shadow"
      >
        <div className="max-w-screen-xl mx-auto ">
          <div className="pt-5 relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className=" mx-auto max-w-screen-xl px-4 sm:px-6  lg:px-8 ">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  <span className="text-blue-600">S3-Compatible</span>
                  <br />
                  affordable, fast <br />
                  reliable <span className="text-blue-600">
                    cloud storage
                  </span>{" "}
                  at {sym}
                  {price} per GB
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  BigStorage is enterprise class, instantly available and allows
                  you to store an infinite amount of data affordably. BigStorage
                  provides an S3-compliant interface to use with storage
                  applications, gateways and other platforms.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="https://app.bigstorage.io/register"
                      className="w-full font-semibold uppercase flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Get started now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={data.placeholderImage.childImageSharp.fluid.src}
            alt=""
          />
        </div>
      </div>
      {sym == "R" ? (
        <div className="inset-x-0 pb-2 sm:pb-5 pt-10">
          <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-2 rounded-lg bg-blue-600 shadow-lg sm:p-3">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="flex p-2 rounded-lg bg-blue-600">
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                      />
                    </svg>
                  </span>
                  <p className="ml-3 font-medium text-white truncate">
                    <span>Looking for USD pricing?</span>
                  </p>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <div className="rounded-md shadow-sm">
                    <a
                      href="/usd"
                      className="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                    >
                      USD Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="inset-x-0 pb-2 sm:pb-5 pt-10">
          <div className="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="p-2 rounded-lg bg-blue-600 shadow-lg sm:p-3">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  <span className="flex p-2 rounded-lg bg-blue-600">
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                      />
                    </svg>
                  </span>
                  <p className="ml-3 font-medium text-white truncate">
                    <span>Looking for ZAR pricing?</span>
                  </p>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <div className="rounded-md shadow-sm">
                    <a
                      href="/"
                      className="flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                    >
                      ZAR Pricing
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="pricing" className="bg-gray-50 overflow-hidden">
        <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="784"
              fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
            />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
            <div className="lg:col-span-1">
              <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Simple <span className="text-blue-600">no-tricks</span> pricing
              </h3>
            </div>
            <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                    />
                  </svg>
                </div>
                <div className="mt-5">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Fair transfer fees
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Free Egress up to 100% of storage utilised per account per
                    month. For transfers in excess of this, {sym}
                    {price} /GB transferred out will apply.
                  </p>
                </div>
              </div>
              <div>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  {sym}
                </div>
                <div className="mt-5">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    Competitive rates
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    We fiercely compete to provide you with the best rates on
                    the continent.
                  </p>
                </div>
              </div>

              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <div className="mt-5">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    First 25 GB free
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    If you store less than 25GB of data our services are
                    completely free! Perfect for smaller applications that need
                    hot storage.
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div className="mt-5">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    {sym}
                    {price} Per GB (Ex VAT)
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Simple and straightforward pay only for what you use, with
                    monthly transparent billing you'll know exactly where your
                    money is going.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container max-w-full mx-auto px-6">
          <div className="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
            <div className="relative block flex flex-col md:flex-row items-center">
              <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-mr-4">
                <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
                  <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                    <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                      Starter
                    </h1>
                    <h2 className="text-sm text-gray-500 text-center pb-6">
                      FREE
                    </h2>
                    For customers who need to store less than 25GB of data and
                    only need to limit access to a select group of people
                  </div>

                  <div className="flex flex-wrap mt-3 px-6">
                    <ul>
                      <li className="flex items-center">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          5 buckets
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          Unlimited ingress
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          1 Mbps egress
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          3 Access keys
                        </span>
                      </li>
                      <li className="flex items-center">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          No credit card required
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="block flex items-center p-8  uppercase">
                    <a
                      href="https://app.bigstorage.io/register"
                      className="mt-3 text-lg font-semibold
  bg-blue-700 w-full text-white rounded-lg
  text-center
	px-6 py-3 block shadow-xl hover:bg-blue-400"
                    >
                      Get started now
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full max-w-md sm:w-2/3 lg:w-1/3 sm:my-5 my-8 relative z-10 bg-white rounded-lg shadow-lg">
                <div className="text-sm leading-none rounded-t-lg bg-gray-200 text-black font-semibold uppercase py-4 text-center tracking-wide">
                  First 25 GB free
                </div>
                <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                  <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                    Standard
                  </h1>
                  <h2 className="text-sm text-gray-500 text-center ">
                    <span className="">
                      {price}
                      {sym}
                    </span>{" "}
                    per GB/mo (ex VAT)
                  </h2>
                  <h2 className="text-sm text-gray-500 text-center pb-3">
                    <span className="">
                      {sym}
                      {serviceCharge}
                    </span>{" "}
                    monthly service charge
                  </h2>
                  Our standard package comes with unlimited storage and robust
                  access control to suit your needs
                </div>
                <div className="flex pl-12 justify-start sm:justify-start mt-3">
                  <ul>
                    <li className="flex items-center">
                      <div className=" rounded-full p-2 fill-current text-green-700">
                        <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                      <span className="text-gray-700 text-lg ml-3">
                        Unlimited ingress
                      </span>
                    </li>
                    <li className="flex items-center">
                      <div className=" rounded-full p-2 fill-current text-green-700">
                        <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                      <span className="text-gray-700 text-lg ml-3">
                        10 Mbps egress
                      </span>
                    </li>
                    <li className="flex items-center">
                      <div className=" rounded-full p-2 fill-current text-green-700">
                        <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                      <span className="text-gray-700 text-lg ml-3">
                        Unlimited access keys
                      </span>
                    </li>

                    <li className="flex items-center">
                      <div className=" rounded-full p-2 fill-current text-green-700">
                        <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                      <span className="text-gray-700 text-lg ml-3">
                        Unlimited buckets
                      </span>
                    </li>
                    <li className="flex items-center">
                      <div className="rounded-full p-2 fill-current text-green-700">
                        <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                      </div>
                      <span className="text-gray-700 text-lg ml-3">
                        Credit card required
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="block flex items-center p-8  uppercase">
                  <a
                    href="https://app.bigstorage.io/register"
                    className="mt-3 text-lg font-semibold
  bg-blue-700 w-full text-white rounded-lg
  text-center
	px-6 py-3 block shadow-xl hover:bg-blue-400"
                  >
                    Get started now
                  </a>
                </div>
              </div>
              <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg md:-ml-4">
                <div className="bg-white text-black rounded-lg shadow-inner shadow-lg overflow-hidden">
                  <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                    <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                      Enterprise
                    </h1>
                    <h2 className="text-sm text-gray-500 text-center pb-6">
                      Tailored to your needs
                    </h2>
                    Our enterprise offering has tailored SLA's, unlimited
                    storage, and customized transfer speeds
                  </div>
                  <div className="flex flex-wrap mt-3 px-6">
                    <ul>
                      <li className="flex items-center">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          Tailored SLA
                        </span>
                      </li>
                      <li className="flex items-center  mt-2">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          Reserved capacity (up to 30% discount for any period
                          1-3 years, from 10TB up)
                        </span>
                      </li>
                      <li className="flex items-center mt-2">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          Dedicated physical connectivity or VPN access
                        </span>
                      </li>
                      <li className="flex items-center  mt-2">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          Enhanced throughput (100Mbps-10Gbps)
                        </span>
                      </li>
                      <li className="flex items-center  mt-2">
                        <div className=" rounded-full p-2 fill-current text-green-700">
                          <svg
                            className="w-6 h-6 align-middle"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">
                          Enhanced Performance Pools(SSD)
                        </span>
                      </li>
                    </ul>

                    <div className="block flex items-center p-8  uppercase">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://support.cloudafrica.net/support/home"
                        className="mt-3 text-lg font-semibold
  bg-blue-700 w-full text-white rounded-lg
  text-center
	px-6 py-3 block shadow-xl hover:bg-blue-400"
                      >
                        Contact Us Today
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        id="about"
        className="relative  overflow-hidden mt-10 overflow-hidden rounded-lg  p-5"
      >
        <h2 className="text-4xl text-center tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Why BigStorage?
        </h2>
      </div>
      <div id="container" className="w-4/5 mx-auto">
        <div className="flex flex-col sm:flex-row">
          <div className="sm:w-1/4 p-2">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
              <div
                className="mb-3 font-semibold text-6xl"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaUserShield />
              </div>
              <h2 className="text-xl font-medium text-gray-700 font-semibold">
                Reliable
              </h2>
              <span className="block mb-5">
                BigStorage is highly available and comes with 24/7 support
              </span>
            </div>
          </div>
          <div className="sm:w-1/4 p-2">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
              <div
                className="mb-3 font-semibold text-6xl"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaAmazon />
              </div>
              <h2 className="text-xl font-medium text-gray-700 font-semibold">
                S3 Compliant
              </h2>
              <span className="block mb-5">
                BigStorage has a fully S3 compatible API
              </span>
            </div>
          </div>
          <div className="sm:w-1/4 p-2">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
              <div
                className="mb-3 font-semibold text-6xl"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaHdd />
              </div>
              <h2 className="text-xl font-medium text-gray-700 font-semibold">
                Unlimited storage
              </h2>
              <span className="block mb-5">Store as much data as you want</span>
            </div>
          </div>
          <div className="sm:w-1/4 p-2">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
              <div
                className="mb-3 font-semibold text-6xl"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaCommentDollar />
              </div>
              <h2 className="text-xl font-medium text-gray-700 font-semibold">
                Simple pricing
              </h2>
              <span className="block mb-5">
                Straightforwad per GB pricing model
              </span>
            </div>
          </div>
          <div className="sm:w-1/4 p-2">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg text-center">
              <div
                className="mb-3 font-semibold text-6xl"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaGlobeAfrica />
              </div>
              <h2 className="text-xl font-medium text-gray-700 font-semibold">
                Hosted in Africa
              </h2>
              <span className="block mb-5">
                Store your data closer to home, improve latency
              </span>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-100"></div>
            <div className="relative max-w-screen-xl mx-auto ">
              <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                <div className="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
                  <h3 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                    What would BigStorage cost me?
                  </h3>
                  <p className="mt-6 text-base leading-6 text-gray-500">
                    Move the sliders below to find out
                  </p>
                  <input
                    className="mt-6 rounded-lg overflow-hidden appearance-none bg-blue-500 h-3"
                    style={{ width: "100%" }}
                    type="range"
                    value={gb}
                    min="25"
                    onChange={(e) => setGb(e.target.value)}
                    max="6000"
                    step="1"
                  />
                  <div>
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Usage
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="number"
                        value={gb}
                        min="25"
                        onChange={(e) => setGb(e.target.value)}
                        id="price"
                        className="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm sm:leading-5"
                          id="price-currency"
                        >
                          GB
                        </span>
                      </div>
                    </div>
                  </div>
                  <input
                    className="mt-6 rounded-lg overflow-hidden appearance-none bg-blue-500 h-3"
                    style={{ width: "100%" }}
                    type="range"
                    value={egress}
                    min={gb}
                    onChange={(e) => setEgress(e.target.value)}
                    max={parseFloat(gb) * 10}
                    step="1"
                  />
                  <div>
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Egress
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="number"
                        value={egress}
                        min="25"
                        onChange={(e) => setEgress(e.target.value)}
                        id="price"
                        className="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm sm:leading-5"
                          id="price-currency"
                        >
                          GB
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-blue-600">
                        What's included
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200"></div>
                    </div>
                    <ul className="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                      <li className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-sm leading-5 text-gray-700">
                          S3 Compliant API
                        </p>
                      </li>
                      <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-sm leading-5 text-gray-700">
                          Flexible access control
                        </p>
                      </li>
                      <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-sm leading-5 text-gray-700">
                          On demand hot storage
                        </p>
                      </li>
                      <li className="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-green-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-sm leading-5 text-gray-700">
                          Unlimited buckets
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    Pay monthly
                  </p>
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    only for what you use
                  </p>
                  <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
                    <span>
                      {sym}
                      {(usage + egressUsage + serviceCharge).toFixed(3)}{" "}
                    </span>
                    <span className="ml-3 text-xl leading-7 font-medium text-gray-500">
                      (ex VAT)
                    </span>
                  </div>
                  <p className="text-2xl mt-3 leading-6 text-gray-500">
                    {parseFloat(gb) > 1000
                      ? (parseFloat(gb) / 1000).toFixed(3)
                      : gb}
                    {parseFloat(gb) > 1000 ? "TB" : "GB"} Storage ({sym}{" "}
                    {usage.toFixed(3)})
                  </p>
                  <p className="text-2xl mt-3 leading-6 text-gray-500">
                    {parseFloat(egress) > 1000
                      ? (parseFloat(egress) / 1000).toFixed(3)
                      : egress}
                    {parseFloat(egress) > 1000 ? "TB" : "GB"} Egress ({sym}{" "}
                    {egressUsage.toFixed(3)})
                  </p>
                  <p className="text-2xl mt-3 leading-6 text-gray-500">
                    Service charge ({sym}
                    {serviceCharge})
                  </p>
                  <div className="mt-6">
                    <div className="rounded-md shadow">
                      <a
                        href="https://app.bigstorage.io/register"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                      >
                        Get Access
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default HomePage;
